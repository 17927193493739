import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'

export const Markdown = new MarkdownIt({
  html: true,
  highlight(code: string, language: string) {
    const result = language ? hljs.highlight(code, { language }) : hljs.highlightAuto(code)
    return result.value
  },
})

export const network = [
  {
    name: 'LinkedIn',
    icon: 'icon-linkedin',
    link: 'https://www.linkedin.com/in/alexbrunocaceres',
  },
  {
    name: 'GitHub',
    icon: 'icon-github',
    link: 'https://github.com/alexbruno',
  },
  {
    name: 'Threads',
    icon: 'icon-threads',
    link: 'https://www.threads.net/@alexbrunocaceres',
  },
  {
    name: 'Instagram',
    icon: 'icon-instagram',
    link: 'https://www.instagram.com/alexbrunocaceres',
  },
]
