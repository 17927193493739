<script setup lang="ts">
import { RouterView } from 'vue-router'
import NavBar from './components/NavBar.vue'
import ThemeSwitch from './components/ThemeSwitch.vue'
import ChatBot from '$src/components/ChatBot.vue'

const dt = new Date()
const year = dt.getFullYear()

function onFontsLoaded(e: Event) {
  const link = e.target as HTMLLinkElement
  link.setAttribute('rel', 'stylesheet')
}
</script>

<template>
  <Teleport to="head">
    <link
      as="style"
      rel="preload"
      href="https://fonts.googleapis.com/css2?family=Noto+Sans&family=Noto+Sans+Mono&family=Noto+Serif&display=swap"
      @load="onFontsLoaded"
    />
  </Teleport>

  <footer>
    <p>alexbruno.dev &copy; {{ year }}</p>
  </footer>

  <main class="content pb-16">
    <RouterView v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </main>

  <ThemeSwitch />
  <NavBar />
  <ChatBot />
</template>

<style scoped lang="pcss">
@import 'tailwindcss';

footer {
  @apply text-center text-sm text-slate-500 p-2 mb-4 print:hidden;
}

/* Vue Transition */
.fade-enter-active,
.fade-leave-active {
  @apply transition-all duration-300;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
/* Vue Transition */
</style>
