<script setup lang="ts">
import { computed } from 'vue'
import { translate } from '$src/modules/i18n'
import { AppStore } from '$src/modules/utils/store'

const title = translate('title')
const text = translate('pages.home.text')
const description = translate('description')
const meta = computed(() => `${title}: ${description}`)

function openAIA() {
  AppStore.chatbot(true)
}
</script>

<template>
  <div class="home">
    <Teleport to="head">
      <title>alexbruno.dev</title>
      <meta name="description" :content="meta" />
    </Teleport>

    <figure class="logo icon-logo text-primary"></figure>
    <h1 class="page-title">Alex Bruno</h1>
    <p class="page-subtitle">{{ title }}</p>
    <hr class="divider" :class="AppStore.state.theme" />
    <small class="text-slate-500">{{ text }}:</small>

    <a href="#" class="aia link ff-noto-serif" @click.prevent="openAIA">AIA</a>
  </div>
</template>

<style scoped lang="pcss">
@import 'tailwindcss';
@custom-variant dark (&:where(.dark, .dark *));

.home {
  @apply flex flex-col items-center justify-center gap-3 text-center max-w-2xl mx-auto mt-5;

  .logo {
    @apply text-[10rem] md:text-[12rem] lg:text-[14rem] xl:text-[16rem];
  }

  .divider {
    @apply w-1/2 my-1.5 transition-all dark:opacity-25;
  }

  .aia {
    @apply text-3xl;
  }
}
</style>
