// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported, logEvent, type Analytics } from 'firebase/analytics'
import {
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  type DocumentData,
} from 'firebase/firestore'
import { useFirestore } from '@vueuse/firebase'

const firebaseConfig = {
  projectId: 'ab-dev-fb',
  measurementId: 'G-5ZLRW0LVTX',
  messagingSenderId: '1047845530386',
  authDomain: 'ab-dev-fb.firebaseapp.com',
  storageBucket: 'ab-dev-fb.firebasestorage.app',
  apiKey: 'AIzaSyBpNdWYV-D6win30kIwhS50Up_d3CO0Rvw',
  appId: '1:1047845530386:web:14e2a52263ba8417e3e1cb',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

class Firebase {
  private analytics = {} as Analytics
  private readonly db = getFirestore(app)

  constructor() {
    if (import.meta.env.DEV) connectFirestoreEmulator(this.db, 'localhost', 8080)
  }

  async setupAnalytics() {
    const ok = await isSupported()
    if (ok) this.analytics = getAnalytics(app)
  }

  logEvent(name: string, params?: Record<string, unknown>) {
    return import.meta.env.PROD && logEvent(this.analytics, name, params)
  }

  useCollection<T extends DocumentData>(name: string) {
    const ref = collection(this.db, name)
    return useFirestore<T>(ref as any)
  }

  useDocument<T extends DocumentData>(collection: string, key: string) {
    const ref = doc(this.db, collection, key)
    return useFirestore<T>(ref as any)
  }

  async getDocument<T>(collection: string, key: string) {
    const ref = doc(this.db, collection, key)
    const obj = await getDoc(ref)

    return obj.data() as T
  }
}

const firebase = new Firebase()

export { firebase }
