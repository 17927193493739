import { createApp } from 'vue'
import { Report } from 'notiflix'

import App from './App.vue'
import router from './router'
import { firebase } from './modules/firebase'
import { translate } from './modules/i18n'

const app = createApp(App)

app.use(router)
app.mount('#app')

// Prod env
if (import.meta.env.PROD) {
  // Firebase Analytics
  firebase.setupAnalytics()

  // Service Worker
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      const sw = await navigator.serviceWorker.register('/sw.js')

      sw.addEventListener('updatefound', () => {
        sw.installing?.addEventListener('statechange', (e) => {
          const target = e.target as ServiceWorker

          if (target.state === 'installed' && navigator.serviceWorker.controller) {
            // Post message to the service worker to skip waiting
            target.postMessage({ type: 'SKIP_WAITING' })

            const update = translate('messages.update')

            // New update available
            Report.info(update.value.title, update.value.text, 'OK', () => location.reload())
          }
        })
      })
    })
  }
}
