import { reactive, watch } from 'vue'
import { Loading } from 'notiflix'

const state = reactive({
  loading: 0,
  theme: 'dark',
  chatbot: false,
})

watch(state, (value) => {
  // Loading
  if (value.loading) Loading.dots()
  else Loading.remove()
})

const AppStore = {
  state,

  loadingStart() {
    state.loading++
  },

  loadingStop() {
    if (state.loading > 0) state.loading--
    else state.loading = 0
  },

  loading(active: boolean) {
    return active ? AppStore.loadingStart() : AppStore.loadingStop()
  },

  chatbot(active: boolean) {
    state.chatbot = active
  },

  theme(theme: string) {
    state.theme = theme
  },
}

export { AppStore }
