import { ref } from 'vue'
import { getLanguage, translate } from '../i18n'
import { firebase } from '../firebase'
import { Markdown, network } from './data'

export interface ChatMessage {
  role: 'user' | 'assistant'
  message: string
  id: number
}

export class ChatAIA {
  session = ref<string>('')
  messages = ref<ChatMessage[]>([])

  get info() {
    const pages = translate('pages')
    const local = translate('local')
    const title = translate('title')
    const description = translate('description')

    const info = {
      author: 'Alex Bruno Cáceres',
      local: local.value,
      title: title.value,
      description: description.value,
      website: 'https://alexbruno.dev',
      email: 'email@alexbruno.dev',
      about: pages.value.about,
      skills: pages.value.skills,
      experience: pages.value.experience,
      contact: pages.value.contact,
      network,
      aia: {
        title: pages.value.aia.title,
        description: pages.value.aia.description,
        info: pages.value.aia.info,
      },
    }

    return info
  }

  start() {
    const message = translate('pages.aia.hello')

    this.session.value = crypto.randomUUID()
    this.messages.value.push({
      role: 'assistant',
      message: message.value,
      id: Date.now(),
    })
  }

  finish() {
    this.messages.value = []
  }

  async message(text: string) {
    firebase.logEvent('aia-message', { text })

    const language = getLanguage()
    const payload = JSON.stringify({
      text,
      language,
      info: this.info,
      session: this.session.value,
    })

    this.messages.value.push({ role: 'user', message: text, id: Date.now() })

    try {
      const chat = await fetch('/api/aia/chat', {
        body: payload,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
      const text = await chat.text()
      const data: ChatMessage = {
        role: 'assistant',
        message: Markdown.render(text),
        id: Date.now(),
      }

      this.messages.value.push(data)

      return data
    } catch (error: any) {
      const message = translate('pages.aia.error')
      this.messages.value.push({
        role: 'assistant',
        message: message.value,
        id: Date.now(),
      })

      console.error(error)
      firebase.logEvent('aia-error', { error })

      throw error
    }
  }
}
