<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToggle } from '@vueuse/core'
import { translate } from '$src/modules/i18n'
import { AppStore } from '$src/modules/utils/store'
import { ChatAIA, type ChatMessage } from '$src/modules/utils/aia'

const router = useRouter()
const chat = new ChatAIA()

const [menu, menuToggle] = useToggle()
const [ready, readyToggle] = useToggle()
const [loading, loadToggle] = useToggle()

const page = translate('pages.aia')
const persons = computed(
  () =>
    new Map<ChatMessage['role'], string>([
      ['user', page.value.you],
      ['assistant', 'AIA'],
    ]),
)

const placeholder = computed(() => (loading.value ? '⏳' : '...'))
const open = computed(() => AppStore.state.chatbot)

watch(open, (ok) => ok && load())

async function load() {
  router.push('/aia')
  setTimeout(() => {
    readyToggle(true)
    chat.start()
  }, 512)
}

async function sendMessage(text: string) {
  loadToggle(true)
  await chat.message(text)
  loadToggle(false)
}

function scroll(e: Element) {
  queueMicrotask(() => e.scrollIntoView({ behavior: 'smooth' }))
}

function onSubmit(e: Event) {
  const form = e.target as HTMLFormElement
  const data = new FormData(form)
  const text = data.get('text') as string

  if (text) {
    form.reset()
    sendMessage(text)
  }
}

function close() {
  chat.finish()
  readyToggle(false)
  AppStore.chatbot(false)
}
</script>

<template>
  <div class="overlay" :class="{ open }">
    <div class="aia">
      <div class="aia-header">
        <h1 class="aia-header-title text-primary">{{ page.title }}</h1>
        <button class="aia-header-close bg-theme" aria-label="Close" @click="close">
          <figure class="icon-x1 text-primary" />
        </button>
      </div>

      <div class="aia-chat">
        <TransitionGroup name="aia-chat-message" @after-enter="scroll">
          <div
            v-for="item in chat.messages.value"
            :key="item.id"
            :class="item.role"
            class="aia-chat-message card"
          >
            <strong class="text-primary" :class="item.role">
              {{ persons.get(item.role) }}
            </strong>
            <div class="aia-chat-message-text" v-html="item.message || page.error.text"></div>
          </div>
        </TransitionGroup>

        <p class="aia-chat-loading" v-if="loading">🤔</p>
      </div>

      <div class="aia-user" :class="{ loading }" v-if="ready">
        <button
          type="button"
          class="aia-user-help aia-bg-theme"
          :aria-label="page.questions.label"
          :title="page.questions.label"
          :disabled="loading"
          @click="menuToggle()"
          @blur="menuToggle(false)"
        >
          <figure class="icon-hash"></figure>
        </button>

        <Transition name="dropup">
          <ul class="aia-user-questions bg-primary" v-if="menu">
            <li
              v-for="(question, index) in page.questions.items"
              :key="index"
              @click="sendMessage(question)"
            >
              {{ question }}
            </li>
          </ul>
        </Transition>

        <form autocomplete="off" class="aia-user-content" @submit.prevent="onSubmit">
          <input
            required
            type="text"
            name="text"
            autocomplete="off"
            class="aia-bg-theme"
            :disabled="loading"
            :placeholder="placeholder"
          />
          <button type="submit" aria-label="OK" class="bg-primary" :disabled="loading">
            <figure class="icon-message-square"></figure>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import 'tailwindcss';
@custom-variant dark (&:where(.dark, .dark *));

.overlay {
  @apply fixed bottom-0 left-1/2;
  @apply flex items-center justify-center shadow;
  @apply backdrop-blur bg-slate-300/50 dark:bg-slate-950/50;
  @apply overflow-hidden w-0 h-0 opacity-0;
  @apply transition-all duration-300;

  &.open {
    @apply inset-0 z-50 w-full h-full opacity-100;
  }
}

.aia {
  @apply w-full h-full flex flex-col max-w-5xl;
  scrollbar-color: teal transparent;

  &-bg-theme {
    @apply bg-slate-100 dark:bg-slate-800;
    @apply border border-slate-300 dark:border-slate-600;
  }

  &-header {
    @apply flex items-center justify-between px-4 py-2 text-xl;

    &-title {
      @apply font-semibold;
    }

    &-close {
      @apply p-2 rounded-full shadow-md cursor-pointer;
    }
  }

  &-chat {
    @apply flex-1 overflow-y-auto px-4;

    &-message {
      @apply px-4 py-3 mb-4 rounded-xl border;

      &.user {
        @apply border-slate-500/50 ml-8 md:ml-16;
      }

      &.assistant {
        @apply border-teal-500/50 mr-8 md:mr-16;
      }
    }

    &-loading {
      @apply text-3xl text-center my-2 animate-bounce;
    }
  }

  &-user {
    @apply flex gap-2 p-2 py-3 relative;

    &.loading {
      @apply animate-pulse;
    }

    &-help {
      @apply cursor-pointer rounded-full px-2 border shadow transition-all;
      @apply text-2xl border-slate-300 dark:border-slate-600;
      @apply focus:ring-teal-500 dark:focus:ring-teal-700;
      @apply focus:border-teal-500 dark:focus:border-teal-700;
      @apply text-slate-500 hover:text-teal-500 focus:text-teal-500;
    }

    &-questions {
      @apply absolute bottom-full left-0 mx-3;
      @apply rounded-xl shadow overflow-hidden border border-white;

      li {
        @apply px-4 py-2 border-b border-white last:border-none;
        @apply cursor-pointer transition-all hover:bg-teal-950/25;
      }
    }

    &-content {
      @apply flex-1 flex gap-2;

      input {
        @apply px-0 flex-1 rounded-full transition-all shadow;
        @apply indent-3 border-slate-300 dark:border-slate-600;
        @apply focus:ring-teal-500 dark:focus:ring-teal-700;
        @apply focus:border-teal-500 dark:focus:border-teal-700;
      }

      button {
        @apply text-2xl rounded-full p-2 shadow cursor-pointer;
      }
    }
  }
}

/* Transition */
.aia-chat-message {
  @apply relative transition-all border-teal-500;
}

.aia-chat-message-enter-active,
.aia-chat-message-leave-active {
  @apply opacity-0 top-8;
}

.aia-chat-message-enter-to,
.aia-chat-message-leave-from {
  @apply opacity-100 top-0;
}

/* Dropup */
.dropup-enter-active,
.dropup-leave-active {
  @apply transition-all;
}
.dropup-enter-from,
.dropup-leave-to {
  @apply opacity-0 translate-y-4;
}
</style>

<style lang="pcss">
@import 'tailwindcss';
@import 'highlight.js/styles/base16/solarized-light.css';

@custom-variant dark (&:where(.dark, .dark *));

.aia-chat-message-text {
  p {
    @apply mb-2;
  }

  strong {
    @apply font-semibold;
  }

  em {
    @apply italic;
  }

  a:not(.link) {
    @apply text-teal-500 hover:underline;
  }

  ul {
    @apply list-outside list-disc pl-8 my-4;

    ul {
      @apply list-none p-0;

      li {
        &::before {
          content: '-';
          @apply inline-block mr-2;
        }
      }
    }
  }

  ol {
    @apply list-outside list-decimal pl-8 my-4;
  }

  code {
    @apply px-1 rounded border bg-slate-100 dark:bg-slate-900;
  }

  pre {
    @apply my-2 px-2 py-1 rounded-xl bg-slate-100 dark:bg-slate-900;

    code {
      @apply block p-2 border-none;
    }
  }
}
</style>
