import { computed, reactive, watch } from 'vue'
import type { RouteLocation } from 'vue-router'
import { getPropertyValue } from '@jsweb/utils/modules/object'

const i18n = reactive({
  language: '',
  translations: {},
})

export function getLanguage() {
  return i18n.language
}

export async function setLanguage(locale: string) {
  i18n.language = locale.startsWith('pt') ? 'pt-BR' : 'en-US'
}

export function setDefaultLanguage(to: RouteLocation) {
  const locale = (to.params.locale as string) ?? navigator.language
  setLanguage(locale)
}

export function translate(key: string) {
  const translation = computed(() => {
    const text = getPropertyValue(i18n.translations, key, {})
    return text
  })
  return translation
}

watch(
  () => i18n.language,
  async (value) => {
    const data = await fetch(`/i18n/${value}.json`)
    i18n.translations = await data.json()
    document.documentElement.lang = value
  },
)
