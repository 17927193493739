<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useDark, useToggle } from '@vueuse/core'
import { translate } from '$src/modules/i18n'
import { AppStore } from '$src/modules/utils/store'

const theme = useDark({
  selector: 'html',
  attribute: 'class',
  valueDark: 'dark',
  valueLight: 'light',
  storageKey: 'theme',
})
const toggle = useToggle(theme)
const icon = computed(() => (theme.value ? 'icon-sun' : 'icon-moon'))
const label = translate('actions.switch.theme')

watch(theme, (value) => {
  const theme = value ? 'dark' : 'light'
  AppStore.theme(theme)
})
</script>

<template>
  <button
    type="button"
    class="theme-switch bg-theme"
    :title="label"
    :aria-label="label"
    @click="toggle()"
  >
    <figure :class="icon" class="icon text-primary" />
  </button>
</template>

<style lang="pcss" scoped>
@import 'tailwindcss';

.theme-switch {
  @apply fixed top-4 right-4 z-10 print:hidden;
  @apply px-2 py-1 rounded-full shadow-md cursor-pointer;

  .icon {
    @apply text-2xl;
  }
}
</style>
