import { createRouter, createWebHistory } from 'vue-router'
import { setLanguage, setDefaultLanguage } from '$src/modules/i18n'
import HomeView from '$src/views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/sobre',
      name: 'sobre',
      component: () => import('$src/views/AboutView.vue'),
      beforeEnter() {
        setLanguage('pt-BR')
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('$src/views/AboutView.vue'),
      beforeEnter() {
        setLanguage('en-US')
      },
    },
    {
      path: '/habilidades',
      name: 'habilidades',
      component: () => import('$src/views/SkillsView.vue'),
      beforeEnter() {
        setLanguage('pt-BR')
      },
    },
    {
      path: '/skills',
      name: 'skills',
      component: () => import('$src/views/SkillsView.vue'),
      beforeEnter() {
        setLanguage('en-US')
      },
    },
    {
      path: '/experiencia',
      name: 'experiencia',
      component: () => import('$src/views/ExperienceView.vue'),
      beforeEnter() {
        setLanguage('pt-BR')
      },
    },
    {
      path: '/experience',
      name: 'experience',
      component: () => import('$src/views/ExperienceView.vue'),
      beforeEnter() {
        setLanguage('en-US')
      },
    },
    {
      path: '/contato',
      name: 'contato',
      component: () => import('$src/views/ContactView.vue'),
      beforeEnter() {
        setLanguage('pt-BR')
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('$src/views/ContactView.vue'),
      beforeEnter() {
        setLanguage('en-US')
      },
    },
    {
      path: '/blog',
      redirect: '/blog/pt-BR',
    },
    {
      path: '/blog/:locale',
      name: 'blog',
      component: () => import('$src/views/blog/BlogIndexView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/blog/:locale/:category',
      name: 'blog-category',
      component: () => import('$src/views/blog/BlogCategoryView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/blog/:locale/:category/:slug',
      name: 'blog-post',
      component: () => import('$src/views/blog/BlogArticleView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('$src/views/NotFoundView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/aia',
      name: 'aia',
      component: () => import('$src/views/ChatbotView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
    {
      path: '/cv',
      name: 'cv',
      component: () => import('$src/views/ResumeView.vue'),
      beforeEnter(to) {
        setDefaultLanguage(to)
      },
    },
  ],
})

export default router
