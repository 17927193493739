<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { translate } from '$src/modules/i18n'
import { AppStore } from '$src/modules/utils/store'

const pages = ['home', 'about', 'skills', 'experience', 'blog', 'contact'].map((key) =>
  translate(`pages.${key}`),
)

function openAIA() {
  AppStore.chatbot(true)
}
</script>

<template>
  <nav class="navbar bg-theme">
    <template v-for="page in pages" :key="page.value.link">
      <RouterLink
        v-if="page.value.link"
        :to="page.value.link"
        :title="page.value.title"
        :aria-label="page.value.title"
        class="link"
      >
        <figure :class="page.value.icon"></figure>
      </RouterLink>
    </template>

    <a href="#" class="link" aria-label="AIA" @click.prevent="openAIA">
      <figure class="icon-message-square" />
    </a>
  </nav>
</template>

<style scoped lang="pcss">
@import 'tailwindcss';

.navbar {
  @apply fixed bottom-3 left-1/2 -translate-x-1/2 z-10;
  @apply flex items-center gap-3 text-3xl print:hidden;
  @apply px-4 py-2 rounded-full shadow-md border border-slate-500/25;

  .link {
    @apply py-1;
  }
}
</style>
